import React from 'react';
import {motion} from 'framer-motion';
import Banner from '../images/about-banner.jpg';
import '../css/About.css';
import OurStory from './OurStory';
import {fadeInAnimationVariant} from '../common/AnimationVariants';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {ValuesData} from './ValuesData';
import OurTeam from './OurTeam';
function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="about-banner">
          <img src={Banner} alt="" />
          <div className="overlay">
            <h1 className="title">About Us</h1>
          </div>
        </div>
        <div className="story">
          <OurStory />
        </div>
        {/* <motion.div
          className="team"
          variants={fadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
        >
          <h1 className="title">Meet The Team</h1>
          <OurTeam />
        </motion.div> */}
        <motion.div
          className="values"
          variants={fadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
        >
          <h1 className="title">Our Values</h1>
          <div className="value-content-wrapper">
            {ValuesData.map((section, index) => (
              <div className="section" key={index}>
                <h2>{section.title}</h2>
                <motion.p
                  initial={{opacity: 0, x: 'auto'}}
                  whileInView={{opacity: 1, x: 0}}
                  transition={{
                    delay: 0.05,
                    duration: 2,
                    type: 'tween',
                    stiffness: 120,
                  }}
                  viewport={{once: true}}
                >
                  {section.content}
                </motion.p>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
      <div className="call-to-action-btn">
        <h1>Join Forces with Us, Let's Make an Impact Together!</h1>
        <Button>
          <Link to="/partner-signup">Partner with Us</Link>
        </Button>
      </div>
    </div>
  );
}

export default About;
