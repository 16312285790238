import React, {useEffect} from 'react';
import '../components/css/App.css';
import {Route, Routes} from 'react-router-dom';
import Nav from '../components/common/Nav';
import Home from '../pages/Home';
import About from '../pages/AboutUs';
import Services from '../pages/Services';
import Education from '../components/services-components/Education';
import Sports from '../components/services-components/Sports';
import Workshops from '../components/services-components/Workshops';
import CommunityEvents from '../components/services-components/CommunityEvent';
import ContactUs from '../pages/ContactUs';
import MainFooter from '../components/common/MainFooter';
import Header from '../components/common/Header';
import ScrollToTop from '../components/common/ScrollToTop';
import NotFoundPage from '../pages/NotFoundPage';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import MembershipSignup from '../pages/MembershipSignup';
import PartnerSignup from '../pages/PartnerSignup';
import VolunteerSignup from '../pages/VolunteerSignup';
import CookieConsent from './CookieConsent';
import CookiePolicy from '../pages/CookiePolicy';
import Blog from '../pages/Blog';
import BlogPostPage from '../components/blog/BlogPostPage';
import Faqs from '../pages/Faqs';
import {analytics} from './FirebaseConfig'; // Import Firebase Analytics
import SafeguardingPolicy from '../pages/SafeguardingPolicy';
import Courses from '../components/education-components/Courses';
import Programs from '../components/sports-components/Programs';
import WorkshopEvents from '../components/workshops-components/WorkshopEvents';
import ExploreEvents from '../components/events-components/ExploreEvents';

const App = () => {
  // Log initial page view event (optional)
  useEffect(() => {
    // analytics.logEvent('page_view');
    // console.log(analytics)
  }, []);

  return (
    <div className="app">
      <CookieConsent />
      <ScrollToTop />
      <Header />
      <Nav />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        <Route path="/services/education" element={<Education />} />
        {/* <Route path="/services/education/courses" element={<Courses />} /> */}
        {/* <Route
          path="/services/education/courses/page/:pageNumber"
          element={<Courses />}
        /> */}
        <Route path="/services/sports" element={<Sports />} />
        {/* <Route path="/services/sports/programs" element={<Programs />} /> */}
        {/* <Route
          path="/services/sports/programs/page/:pageNumber"
          element={<Programs />}
        /> */}
        <Route path="/services/workshops" element={<Workshops />} />
        {/* <Route path="/services/workshops/events" element={<WorkshopEvents />} /> */}
        {/* <Route
          path="/services/workshops/events/page/:pageNumber"
          element={<WorkshopEvents />}
        /> */}
        <Route
          path="/services/community-events"
          element={<CommunityEvents />}
        />
        {/* <Route
          path="/services/community-events/explore"
          element={<ExploreEvents />}
        /> */}
        {/* <Route
          path="/services/community-events/explore/page/:pageNumber"
          element={<ExploreEvents />}
        /> */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/page/:pageNumber" element={<Blog />} />
        <Route path="/blog/:postId" element={<BlogPostPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/membership-signup" element={<MembershipSignup />} />
        <Route path="/partner-signup" element={<PartnerSignup />} />
        <Route path="/volunteer-signup" element={<VolunteerSignup />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/safeguarding-policy" element={<SafeguardingPolicy />} />
        <Route path="*" element={<NotFoundPage />} /> {/* Handle 404 route */}
      </Routes>
      <MainFooter />
    </div>
  );
};

export default App;
