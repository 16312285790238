import React from 'react';
import '../css/Education.css';
import {Button} from '@material-ui/core';
import bannerImg from '../images/education-banner.jpg';
import EducationServices from '../education-components/EducationServices';
import {useNavigate} from 'react-router';

const Education = () => {
  const navigate = useNavigate();
  return (
    <div className="education">
      <div className="education-container">
        <div className="main-header">
          <div className="wrapper">
            <div className="main-header-content">
              <h1 className="title">Empowerment through Education:</h1>
              <p>Equipping Minds, Building Futures, and Cultivating Success</p>
            </div>
            <div className="btn">
              {/* <Button
                className="learn-btn"
                onClick={() => navigate('/services/education/courses')}
              >
                Explore
              </Button>
              <Button
                className="teach-btn"
                onClick={() => navigate('/membership-signup')}
              >
                Enroll
              </Button> */}
            </div>
          </div>
          <div className="image">
            <img src={bannerImg} alt="Banner" />
          </div>
        </div>
        <EducationServices />
      </div>
      <div className="call-to-action-btn">
        <h1>Ready to empower your mind through education?</h1>
        <Button onClick={() => navigate('/membership-signup')}>
          Enroll Now
        </Button>
      </div>
    </div>
  );
};

export default Education;
