import React from 'react';
import '../css/Sports.css';
import {Button} from '@material-ui/core';
import bannerImg from '../images/sports-banner.jpg';
import SportServices from '../sports-components/SportServices';
import {useNavigate} from 'react-router-dom';

const Sports = () => {
  const navigate = useNavigate();
  return (
    <div className="sports">
      <div className="sports-container">
        <div className="main-header">
          <div className="wrapper">
            <div className="main-header-content">
              <h1 className="title">
                Empower yourself through health and wellbeing to unlock your
                true potential.
              </h1>{' '}
              <p>
                {' '}
                Nourish Your Body, Nurture Your Mind: Your Pathway to Total
                Wellbeing
              </p>{' '}
            </div>
            <div className="btn">
              {/* <Button
                className="learn-btn"
                onClick={() => navigate('/services/sports/programs')}
              >
                Explore
              </Button>{' '}
              <Button
                className="teach-btn"
                onClick={() => navigate('/membership-signup')}
              >
                Join
              </Button>{' '} */}
            </div>
          </div>
          <div className="image">
            <img src={bannerImg} alt="Banner" />
          </div>
        </div>
        <SportServices />
      </div>
      <div className="call-to-action-btn">
        <h1>
          Explore our Health and Wellbeing Services to embark on your journey
          towards a vibrant and balanced life.
        </h1>{' '}
        <Button onClick={() => navigate('/membership-signup')}>Join Us</Button>
      </div>
    </div>
  );
};

export default Sports;
