import React from 'react';
import '../css/Workshops.css';
import {Button} from '@material-ui/core';
import bannerImg from '../images/workshop-banner.jpg';
import WorkshopServices from '../workshops-components/WorkshopServices';
import {useNavigate} from 'react-router';

const Workshops = () => {
  const navigate = useNavigate();
  return (
    <div className="workshops">
      <div className="workshops-container">
        <div className="main-header">
          <div className="wrapper">
            <div className="main-header-content">
              <h1 className="title">Explore New Horizons with Workshops</h1>
              <p>Unlock Your Creativity and Learn New Skills</p>
            </div>
            <div className="btn">
              {/* <Button
                className="bg-btn"
                onClick={() => navigate('/services/workshops/events')}
              >
                Discover
              </Button>
              <Button
                className="border-btn"
                onClick={() => navigate('/membership-signup')}
              >
                Enroll
              </Button> */}
            </div>
          </div>
          <div className="image">
            <img src={bannerImg} alt="Banner" />
          </div>
        </div>
        <WorkshopServices />
      </div>
      <div className="call-to-action-btn">
        <h1>
          Join us for our upcoming workshops and embark on a transformative
          journey of learning, growth, and empowerment at Unity Horizons.
        </h1>
        <Button onClick={() => navigate('/membership-signup')}>Join Us</Button>
      </div>
    </div>
  );
};

export default Workshops;
